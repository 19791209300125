var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      ref: "contentToPrint",
      staticClass: "active-voucher-detail pb-0 pt-12 px-0",
      attrs: { fluid: "", "fill-height": "", id: "contentToPrint" }
    },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            {
              staticClass:
                "d-flex flex-column align-center justify-space-between",
              attrs: { xs12: "" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column text-center px-2 flex-grow-1"
                },
                [
                  _c("h2", { staticClass: "primary--text pre-wrap" }, [
                    _vm._v(" " + _vm._s(_vm.formatedName) + " ")
                  ]),
                  _c("barcode", {
                    staticClass: "align-self-center",
                    attrs: {
                      value: _vm.coupon.giftCode,
                      format: "EAN13",
                      displayValue: false,
                      height: "80px",
                      flat: ""
                    }
                  }),
                  _c("div", { staticClass: "text-body-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("coupons.discounts.numericalCode")) +
                        " "
                    )
                  ]),
                  _c("h1", { staticClass: "default--text mt-0" }, [
                    _vm._v(_vm._s(_vm.coupon.giftCode))
                  ]),
                  _c("div", { staticClass: "mb-7" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("coupons.discounts.valid", {
                            startDate: this.$dayjs(
                              _vm.coupon.startDate,
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY"),
                            endDate: this.$dayjs(
                              _vm.coupon.endDate,
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")
                          })
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "ol",
                    { staticClass: "mt-3 text-body-3 disclaimer-messages" },
                    _vm._l(_vm.disclaimerMessages, function(disclaimer, index) {
                      return _c("li", { key: "disclaimer" + index }, [
                        _vm._v(" " + _vm._s(_vm.$t(disclaimer)) + " ")
                      ])
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "bottom-section d-flex flex-column align-center grey lighten-1 text-center px-2 w-100 py-6 mt-3"
                },
                [
                  _c("img", {
                    staticClass: "coupon-img rounded-lg",
                    attrs: {
                      src: _vm.coupon.categoryProposal.img,
                      onerror: "this.onerror=null;this.src='/no-icon.png'",
                      alt: _vm.coupon.categoryProposal.imgAlt
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pt-2 pb-4 text-body-0 default--text text-decoration-underline",
                      attrs: { "data-html2canvas-ignore": "true" },
                      on: { click: _vm.print }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "default" } },
                        [_vm._v("$download")]
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("coupons.discounts.downloadYourVoucher")
                          )
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }